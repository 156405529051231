import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Share from '../components/share'
import { unescapePunctuation } from '../utilities/formatters'
import { ParallaxProvider, Parallax } from 'react-scroll-parallax'
import TfttSquare from '../assets/images/logos/tfttsquare.svg'
import { ChevronRight } from 'react-feather'
import { useWebPSupportCheck } from "react-use-webp-support-check";

import EotwFlat from '../assets/images/eotw-flat.svg'

import BackgroundBackgroundPng from '../assets/images/eotw/background/background.png'
import BackgroundMidgroundPng from '../assets/images/eotw/background/midground.png'
import BackgroundMountainLeftPng from '../assets/images/eotw/background/mountain-left.png'
import BackgroundMountainRightPng from '../assets/images/eotw/background/mountain-right.png'
import BackgroundIceLeftPng from '../assets/images/eotw/background/ice-left.png'
import BackgroundIceRightPng from '../assets/images/eotw/background/ice-right.png'

import BackgroundBackgroundWebp from '../assets/images/eotw/background/background.webp'
import BackgroundMidgroundWebp from '../assets/images/eotw/background/midground.webp'
import BackgroundMountainLeftWebp from '../assets/images/eotw/background/mountain-left.webp'
import BackgroundMountainRightWebp from '../assets/images/eotw/background/mountain-right.webp'
import BackgroundIceLeftWebp from '../assets/images/eotw/background/ice-left.webp'
import BackgroundIceRightWebp from '../assets/images/eotw/background/ice-right.webp'

export const EotwTemplate = ({ title, content, path, art, episode, latest_posts }) => {
  const contentParsed = content.replace(/http:\/\/18.235.35.120/g, "https://content.tfttpresents.com").replace(/http:\/\/admin.tfttpresents.com/g, "https://content.tfttpresents.com");
  const supportsWebP = useWebPSupportCheck();
  if (false) {
    const post0 = latest_posts[0].node;
    const post1 = latest_posts[1].node;
  }
  return (
    <div className="page--eotw">
      <ParallaxProvider>
        <section className="hero hero--eotw" style={{"backgroundImage":`url(${supportsWebP ? BackgroundBackgroundWebp : BackgroundBackgroundPng})`}}>
          <Parallax className="eotw__logo" y={["-200px", "250px"]} tagOuter="figure">
          <img src={EotwFlat}/>
          </Parallax>
          <Parallax className="eotw__midground midground" y={["-50px","50px"]} tagOuter="figure">
            <figure style={{"backgroundImage":`url(${supportsWebP ? BackgroundMidgroundWebp : BackgroundMidgroundPng})`}}></figure>
          </Parallax>
          <Parallax className="eotw__mountain mountain--left" y={["-150px","150px"]} tagOuter="figure">
            <figure style={{"backgroundImage":`url(${supportsWebP ? BackgroundMountainLeftWebp : BackgroundMountainLeftPng})`}}></figure>
          </Parallax>
          <Parallax className="eotw__mountain mountain--right" y={["-200px","200px"]} x={["-50px","50px"]} tagOuter="figure">
            <figure style={{"backgroundImage":`url(${supportsWebP ? BackgroundMountainRightWebp : BackgroundMountainRightPng})`}}></figure>
          </Parallax>
          <Parallax className="eotw__ice ice--left" x={["0px","-220px"]} tagOuter="figure">
            <figure style={{"backgroundImage":`url(${supportsWebP ? BackgroundIceLeftWebp : BackgroundIceLeftPng})`}}></figure>
          </Parallax>
          <Parallax className="eotw__ice ice--right" x={["0px","180px"]} tagOuter="figure">
            <figure style={{"backgroundImage":`url(${supportsWebP ? BackgroundIceRightWebp : BackgroundIceRightPng})`}}></figure>
          </Parallax>
        </section>


        <article
          className="content content--eotw"
          role="article"
          dangerouslySetInnerHTML={{ __html: contentParsed }}
        />

        {false &&
          <div className="grid eotw__grid">
            <Link to={episode.path} style={{"backgroundImage":`url(${episode.featured_media.source_url}`}} className="grid__item grid__item-1">
            <section>
              <small>Newest Episode</small>
              <h3>{episode.title}</h3>
            </section>
            </Link>

            <div className="grid__item grid__item--post grid__item-2">
              <aside>
                <Link to={post0.path} className="post__image" style={{"backgroundImage": `url(${post0.featured_media ? post0.featured_media.source_url : TfttSquare})`}} />
              </aside>
              <main>
                <Link to={`${post0.path}`}>
                  <small>Recent Post</small>
                  <h3>{unescapePunctuation(post0.title)}</h3>
                </Link>
                <section>
                  {!!post0.categories.length &&
                    <ul className="post__tag-category post__tag-category--category">

                      {post0.categories.map((category, index) => {
                        return (<li key={category.slug}><Link to={`/categories/${category.slug}`}>{category.name}</Link></li>)
                      })}
                    </ul>
                  }
                  {post0.excerpt &&
                    <div className="post__excerpt"
                      dangerouslySetInnerHTML={{
                        __html: post0.excerpt.replace(/<p class="link-more.*/, ''),
                      }}
                    />
                  }
                  {!!post0.tags.length &&
                  <ul className="post__tag-category post__tag-category--tag">
                    <li><span className="tag-category__head">Tagged:</span></li>
                    {post0.tags.map((tag, index) => {
                      return (<li key={tag.slug}><Link to={`/tags/${tag.slug}`}>{tag.name}</Link></li>)
                    })}
                  </ul>
                  }
                </section>
              </main>
            </div>

            <div className="grid__item grid__item--post grid__item-3">
              <aside>
                <Link to={post1.path} className="post__image" style={{"backgroundImage": `url(${post1.featured_media ? post1.featured_media.source_url : TfttSquare})`}} />
              </aside>
              <main>
                <Link to={`${post1.path}`}>
                  <small>Recent Post</small>
                  <h3>{unescapePunctuation(post1.title)}</h3>
                </Link>
                <section>
                  {!!post1.categories.length &&
                    <ul className="post__tag-category post__tag-category--category">

                      {post1.categories.map((category, index) => {
                        return (<li key={category.slug}><Link to={`/categories/${category.slug}`}>{category.name}</Link></li>)
                      })}
                    </ul>
                  }
                  {post1.excerpt &&
                    <div className="post__excerpt"
                      dangerouslySetInnerHTML={{
                        __html: post1.excerpt.replace(/<p class="link-more.*/, ''),
                      }}
                    />
                  }
                  {!!post1.tags.length &&
                  <ul className="post__tag-category post__tag-category--tag">
                    <li><span className="tag-category__head">Tagged:</span></li>
                    {post1.tags.map((tag, index) => {
                      return (<li key={tag.slug}><Link to={`/tags/${tag.slug}`}>{tag.name}</Link></li>)
                    })}
                  </ul>
                  }
                </section>
              </main>
            </div>

            <Link to={art.path} style={{"backgroundImage":`url(${art.featured_media.source_url}`}} className="grid__item grid__item-4">
              <section>
                <small>Newest Art</small>
                <h3>{art.title}</h3>
              </section>
            </Link>
            <Link to="/bailehart" className="grid__item grid__item--link grid__item-5">
              <span>Explore Bailehart</span>
              <ChevronRight />
            </Link>
            <Link to="/listen" className="grid__item grid__item--link grid__item-6">
              <span>Subscribe to podcast</span>
              <ChevronRight />
            </Link>
            <Link to="/categories/edge-of-the-world" className="grid__item grid__item--link grid__item-7">
              <span>See all Edge of the World posts</span>
              <ChevronRight />
            </Link>
          </div>
        }


        <aside className="post__meta">
          <Share
            title={unescapePunctuation(title)}
            path={path}
          />
        </aside>
      </ParallaxProvider>
    </div>
  )
}

EotwTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
}

const EotwPage = ({ data }) => {
  const { wordpressPage: page, art: art, episode: episode, latest_posts: latest_posts } = data

  let _art, _episode, _latest_posts = null;
  if(art.edges && art.edges.length) {
    _art = art.edges[0].node;
  }
  if(episode.edges && episode.edges.length) {
    _episode = episode.edges[0].node;
  }
  if(latest_posts.edges && latest_posts.edges.length == 2) {
    _latest_posts = latest_posts.edges;
  }

  return (
    <Layout>
      <EotwTemplate title={page.title} content={page.content} path={page.path} art={_art} episode={_episode} latest_posts={_latest_posts} />
    </Layout>
  )
}

EotwPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default EotwPage

export const pageQuery = graphql`
  query EotwPageById($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      path
    }

    art:allWordpressPost(
      filter: {categories: {elemMatch: {slug: {eq: "edge-of-the-world"}}}, tags: {elemMatch: {slug: {eq: "art"}}}},
      sort: { fields: date, order: DESC },
      limit: 1) {
      edges {
        node {
          id
          featured_media {
            source_url
          }
          path
          title
        }
      }
    }

    episode:allWordpressPost(
      filter: {categories: {elemMatch: {slug: {eq: "edge-of-the-world"}}}, tags: {elemMatch: {slug: {eq: "youtube"}}}},
      sort: { fields: date, order: DESC },
      limit: 1) {
      edges {
        node {
          id
          featured_media {
            source_url
          }
          path
          title
        }
      }
    }

    latest_posts:allWordpressPost(
      filter: {categories: {elemMatch: {slug: {eq: "edge-of-the-world"}}}, tags: {elemMatch: {slug: {nin: ["art", "fan-art", "youtube"]}}}},
      sort: { fields: date, order: DESC },
      limit: 2) {
      edges {
        node {
          ...PostListFields
        }
      }
    }
  }
`
